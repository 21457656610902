import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { FC } from 'react'
import { SelectItem } from '../../../interfaces/common'
import { FormProps } from '../../../interfaces/form'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& fieldset': {
      border: `solid ${theme.palette.primary.main} 1px`,
    },
  },
  textfield: {
    '& div': {
      borderRadius: theme.spacing(2),
      height: theme.spacing(6),
      '& input': {
        paddingTop: `${theme.spacing(0.5)}px !important`,
      },
    },
  },
}))

interface SearchAutocompleteProps {
  label?: string
  name: string
  value: string
  options: SelectItem[]
  placeholder?: string
  noOptionText?: string
  disabled?: boolean
  handleChange(payload: FormProps): void
}

const getOptionsLabel = (options: SelectItem[]): string[] =>
  options.map(o => o.label)
const getOptionsValueByLabel = (
  options: SelectItem[],
  label: string,
): string => {
  const correspondingValue = options.find(o => o.label === label)
  return correspondingValue ? correspondingValue.value : ''
}

const getOptionsLabelByValue = (
  options: SelectItem[],
  value: string,
): string => {
  const correspondingLabel = options.find(o => o.value === value)
  return correspondingLabel ? correspondingLabel.label : ''
}

const SearchAutocomplete: FC<SearchAutocompleteProps> = props => {
  const { label, options, noOptionText, placeholder, disabled } = props
  const optionsLabel = getOptionsLabel(options)
  const currentLabel = getOptionsLabelByValue(options, props.value)

  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: any,
  ) => {
    const selectedLabel = value || ''
    const selectedValue = getOptionsValueByLabel(options, selectedLabel)

    props.handleChange({ name: props.name, value: selectedValue })
  }

  return (
    <Autocomplete
      className={classes.root}
      value={currentLabel}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={optionsLabel}
      noOptionsText={noOptionText || 'Aucune option'}
      // style={{ width: '100%' }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          className={classes.textfield}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
    />
  )
}

export default SearchAutocomplete
