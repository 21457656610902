import { Box } from '@material-ui/core'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { ActivitySections } from '../../../../hooks/nomac/useActivitySections'
import { RootState } from '../../../../redux/store'
import SectionLine from '../sectionLine'

interface SectionsListProps {
  sections: ActivitySections[]
  handleSelectSection: (id: string) => () => void
}

const SectionsList: FC<SectionsListProps> = props => {
  const { sections, handleSelectSection } = props

  return (
    <Box>
      {sections.map(s => (
        <SectionLine section={s} handleClick={handleSelectSection(s.id)} />
      ))}
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  sections: state.nomac.data.sections,
})

export default connect(mapStateToProps)(SectionsList)
