import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { connect, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/store'
import NomacCircle from '../../nomacCircle'
import NomacStepLine from '../../nomacStepLine'
import {
  updateSelectedNomac,
  UpdateSelectedNomac,
} from '../../../../../../../redux/modules/nomac'
import { NomacSelection } from '../../../../../../../interfaces/nomac'
import { ActivityDivisions } from '../../../../../../../hooks/nomac/useActivityDivisions'
import { getActivityCode } from '../../../../../../../utils/nomac'
import { getInternationalizedValue } from '../../../../../../../utils/common'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  divider: {
    marginLeft: theme.spacing(1.75),
    width: 4,
    backgroundColor: '#E5E5E5',
    borderRadius: 4,
    minHeight: theme.spacing(2),
  },
}))

interface DivisionsProps {
  selected: NomacSelection
  divisions: ActivityDivisions[]
  updateSelectedNomac: UpdateSelectedNomac
}

const Divisions: FC<DivisionsProps> = props => {
  const { selected, divisions, updateSelectedNomac } = props

  const { language } = useSelector((state: RootState) => state.app)
  const classes = useStyles()

  const sectionCode = getActivityCode(selected.sectionId)
  const currentDivisions = selected.divisionId
    ? divisions.filter(d => d.id === selected.divisionId) // Display only the selected division if one selected
    : divisions.filter(d => d.codeSectionActivite === sectionCode)

  const handleSelectDivision = (divisionId: string) => () => {
    updateSelectedNomac({ ...selected, divisionId })
  }

  const handleResetDivision = () => {
    // Reset division and step bellow it
    updateSelectedNomac({
      ...selected,
      divisionId: '',
      groupId: '',
      classeId: '',
      categoryId: '',
    })
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <NomacCircle />
        <Box>
          <Typography variant="h6"> Divisions </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box className={classes.divider} />
        <Box width="100%" paddingY={1} paddingX={3}>
          {currentDivisions.map(d => {
            const code = getActivityCode(d.id)
            const label = getInternationalizedValue(
              d,
              'libelleDivisionActivite',
              language,
            )
            const note = getInternationalizedValue(d, 'note', language)
            return (
              <NomacStepLine
                key={`divisions-${d.id}`}
                code={code}
                label={label}
                note={note}
                isSelected={selected.divisionId === d.id}
                handleSelect={handleSelectDivision(d.id)}
                handleReturn={handleResetDivision}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  selected: state.nomac.selection,
  divisions: state.nomac.data.divisions,
})

const mapDispatchToProps = {
  updateSelectedNomac,
}

export default connect(mapStateToProps, mapDispatchToProps)(Divisions)
