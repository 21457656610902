import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import MoreIcon from '@material-ui/icons/ExpandMore'
import { ActivitySections } from '../../../../hooks/nomac/useActivitySections'
import { getActivityCode } from '../../../../utils/nomac'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    borderBottom: 'solid rgba(0, 0, 0, 0.25) 1px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  heading: {
    textAlign: 'center',
  },
}))

interface SectionLineProps {
  section: ActivitySections
  handleClick(): void
}

const SectionLine: FC<SectionLineProps> = props => {
  const { section, handleClick } = props
  const classes = useStyles()

  const code = getActivityCode(section.id)
  const { language } = useSelector((state: RootState) => state.app)
  const label = getInternationalizedValue(
    section,
    'libelleSectionActivite',
    language,
  )

  return (
    <Box onClick={handleClick}>
      <Box className={classes.container}>
        <Box marginRight={2}>
          <Typography variant="h5">{code}</Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h5" className={classes.heading}>
            {label}
          </Typography>
        </Box>
        <Box>
          <MoreIcon />
        </Box>
      </Box>
    </Box>
  )
}

export default SectionLine
