import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import TextMarkdown from '../components/customMarkdown/textMarkdown'
import SearchAutocomplete from '../components/forms/searchAutocomplete'
import NomacStep from '../components/pages/nomac/nomacStep'
import SectionsList from '../components/pages/nomac/sectionsList'
import SEO from '../components/seo'
import useActivityCategories from '../hooks/nomac/useActivityCategories'
import useActivityClasses from '../hooks/nomac/useActivityClasses'
import useActivityDivisions from '../hooks/nomac/useActivityDivisions'
import useActivityGroups from '../hooks/nomac/useActivityGroups'
import useActivitySections from '../hooks/nomac/useActivitySections'
import useNomacIntroduction from '../hooks/nomac/useNomacIntroduction'
import { SelectItem } from '../interfaces/common'
import { FormProps } from '../interfaces/form'
import { NomacSelection } from '../interfaces/nomac'
import {
  FillOutNomacData,
  fillOutNomacData,
  UpdateSelectedNomac,
  updateSelectedNomac,
} from '../redux/modules/nomac'
import { RootState } from '../redux/store'
import { getInternationalizedValue } from '../utils/common'
import { getActivityCode, getNomacOptions } from '../utils/nomac'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  container: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(3),

    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  titleContainer: {
    // paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  subtitle: {
    fontWeight: 'bold',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface NomacProps {
  selected: NomacSelection
  updateSelectedNomac: UpdateSelectedNomac
  fillOutNomacData: FillOutNomacData
}

const Nomac: FC<NomacProps> = props => {
  const { selected, fillOutNomacData, updateSelectedNomac } = props

  const classes = useStyles()

  const sections = useActivitySections()
  const divisions = useActivityDivisions()
  const groups = useActivityGroups()
  const classesData = useActivityClasses()
  const categories = useActivityCategories()
  const introData = useNomacIntroduction()
  const { language } = useSelector((state: RootState) => state.app)

  const [nomacOptions, setNomacOptions] = useState<SelectItem[]>([])
  const [nomacSearch, setNomacSearch] = useState('')

  const introIntl = getInternationalizedValue(
    introData,
    'introduction',
    language,
  )

  useEffect(() => {
    setNomacOptions(getNomacOptions(categories, language))
  }, [categories.length])

  fillOutNomacData({ sections, divisions, groups, classesData, categories })

  const handleSelectSection = (sectionId: string) => () => {
    updateSelectedNomac({ ...selected, sectionId })
  }

  const handleSearchChange = (args: FormProps) => {
    const { value } = args
    setNomacSearch(`${value}`)
    const selectedCategory = categories.find(
      c => getActivityCode(c.id) === value,
    )
    if (selectedCategory) {
      const {
        codeSection,
        codeDivision,
        codeGroupe,
        codeClasse,
      } = selectedCategory
      updateSelectedNomac({
        sectionId: `Activity-sections_${codeSection}`,
        divisionId: `Activity-divisions_${codeDivision}`,
        groupId: `Activity-groups_${codeGroupe}`,
        classeId: `Activity-classes_${codeClasse}`,
        categoryId: selectedCategory.id,
      })
    }
  }

  const handleResetNomac = () => {
    setNomacSearch('')
  }

  return (
    <Box className={classes.root}>
      <SEO title="Activities Code" />

      <Box className={classes.titleContainer}>
        <Typography variant="h4">DÉTERMINER VOTRE ACTIVITÉ</Typography>
      </Box>

      <Box marginY={2}>
        <TextMarkdown text={introIntl} />
      </Box>

      <Box paddingY={4} className={classes.searchContainer}>
        <Box width="70%">
          <SearchAutocomplete
            name="nomac"
            value={nomacSearch}
            options={nomacOptions}
            handleChange={handleSearchChange}
            noOptionText="Aucune activité trouvée"
            placeholder="Rechercher une catégorie"
          />
        </Box>
      </Box>

      <Box className={classes.container}>
        <Box paddingBottom={4}>
          <Typography variant="h5" className={classes.subtitle}>
            SECTEURS D'ACTIVITÉ
          </Typography>
        </Box>
        {selected.sectionId ? (
          <NomacStep handleResetNomac={handleResetNomac} />
        ) : (
          <SectionsList handleSelectSection={handleSelectSection} />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  selected: state.nomac.selection,
})

const mapDispatchToProps = {
  updateSelectedNomac,
  fillOutNomacData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nomac)
