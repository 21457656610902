import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { connect, useSelector } from 'react-redux'
import { ActivityGroups } from '../../../../../../../hooks/nomac/useActivityGroups'
import { NomacSelection } from '../../../../../../../interfaces/nomac'
import {
  updateSelectedNomac,
  UpdateSelectedNomac,
} from '../../../../../../../redux/modules/nomac'
import { RootState } from '../../../../../../../redux/store'
import { getInternationalizedValue } from '../../../../../../../utils/common'
import { getActivityCode } from '../../../../../../../utils/nomac'
import NomacCircle from '../../nomacCircle'
import NomacStepLine from '../../nomacStepLine'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  divider: {
    marginLeft: theme.spacing(1.75),
    width: 4,
    backgroundColor: '#E5E5E5',
    borderRadius: 4,
    minHeight: theme.spacing(2),
  },
}))

interface GroupsProps {
  selected: NomacSelection
  groups: ActivityGroups[]
  updateSelectedNomac: UpdateSelectedNomac
}

const Groups: FC<GroupsProps> = props => {
  const { selected, groups, updateSelectedNomac } = props

  const classes = useStyles()
  const { language } = useSelector((state: RootState) => state.app)

  const divisionCode = getActivityCode(selected.divisionId)

  const currentGroups = selected.groupId
    ? groups.filter(g => g.id === selected.groupId) // Display only the selected group if one selected
    : groups.filter(g => g.idDivisionActivite === divisionCode)

  const handleSelectGroups = (groupId: string) => () => {
    updateSelectedNomac({ ...selected, groupId })
  }

  const handleResetGroups = () => {
    // Reset group and step bellow it
    updateSelectedNomac({
      ...selected,
      groupId: '',
      classeId: '',
      categoryId: '',
    })
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <NomacCircle />
        <Box>
          <Typography variant="h6"> Groupes </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box className={classes.divider} />
        <Box width="100%" paddingY={1} paddingX={3}>
          {currentGroups.map(g => {
            const code = getActivityCode(g.id)
            const label = getInternationalizedValue(
              g,
              'libelleGroupeActivite',
              language,
            )
            const note = getInternationalizedValue(g, 'note', language)
            return (
              <NomacStepLine
                key={`divisions-${g.id}`}
                code={code}
                label={label}
                note={note}
                isSelected={selected.groupId === g.id}
                handleSelect={handleSelectGroups(g.id)}
                handleReturn={handleResetGroups}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  selected: state.nomac.selection,
  groups: state.nomac.data.groups,
})

const mapDispatchToProps = {
  updateSelectedNomac,
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups)
