import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { connect, useSelector } from 'react-redux'
import { ActivitySections } from '../../../../../../../hooks/nomac/useActivitySections'
import { NomacSelection } from '../../../../../../../interfaces/nomac'
import {
  updateSelectedNomac,
  UpdateSelectedNomac,
} from '../../../../../../../redux/modules/nomac'
import { RootState } from '../../../../../../../redux/store'
import { getInternationalizedValue } from '../../../../../../../utils/common'
import { getActivityCode } from '../../../../../../../utils/nomac'
import NomacCircle from '../../nomacCircle'
import NomacStepLine from '../../nomacStepLine'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  divider: {
    marginLeft: theme.spacing(1.75),
    width: 4,
    backgroundColor: '#E5E5E5',
    borderRadius: 4,
    minHeight: theme.spacing(2),
  },
}))

interface SectionsProps {
  selected: NomacSelection
  sections: ActivitySections[]
  updateSelectedNomac: UpdateSelectedNomac
}

const Sections: FC<SectionsProps> = props => {
  const { selected, sections, updateSelectedNomac } = props

  const selectedSection = sections.find(s => s.id === selected.sectionId)
  const code = selectedSection ? getActivityCode(selectedSection.id) : ''

  const { language } = useSelector((state: RootState) => state.app)
  const label = selectedSection
    ? getInternationalizedValue(
        selectedSection,
        'libelleSectionActivite',
        language,
      )
    : ''
  const note = selectedSection
    ? getInternationalizedValue(selectedSection, 'note', language)
    : ''

  const classes = useStyles()

  const handleReturn = () => {}

  const handleResetSection = () => {
    updateSelectedNomac({
      sectionId: '',
      divisionId: '',
      groupId: '',
      classeId: '',
      categoryId: '',
    })
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <NomacCircle />
        <Box>
          <Typography variant="h6"> Sections </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box className={classes.divider} />
        {selectedSection ? (
          <Box width="100%" paddingY={1} paddingX={3}>
            <NomacStepLine
              code={code}
              label={label}
              note={note}
              isSelected={true}
              handleSelect={handleReturn}
              handleReturn={handleResetSection}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  selected: state.nomac.selection,
  sections: state.nomac.data.sections,
})

const mapDispatchToProps = {
  updateSelectedNomac,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sections)
