import { Box, Button } from '@material-ui/core'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import {
  updateSelectedNomac,
  UpdateSelectedNomac,
} from '../../../../redux/modules/nomac'
import Categories from './components/steps/categories'
import ClassesStep from './components/steps/classesStep'
import Divisions from './components/steps/divisions'
import Groups from './components/steps/groups'
import Sections from './components/steps/sections'

interface NomacStepProps {
  updateSelectedNomac: UpdateSelectedNomac
  handleResetNomac(): void
}

const NomacStep: FC<NomacStepProps> = props => {
  const { handleResetNomac, updateSelectedNomac } = props

  const handleResetSelection = () => {
    updateSelectedNomac({
      sectionId: '',
      divisionId: '',
      groupId: '',
      classeId: '',
      categoryId: '',
    })
    handleResetNomac()
  }

  return (
    <Box>
      <Sections />
      <Divisions />
      <Groups />
      <ClassesStep />
      <Categories />
      <Box marginY={3} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetSelection}
        >
          Recommencer
        </Button>
      </Box>
    </Box>
  )
}

const mapDispatchToProps = {
  updateSelectedNomac,
}

export default connect(null, mapDispatchToProps)(NomacStep)
