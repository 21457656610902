import { Box, IconButton, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import ArrowIcon from '@material-ui/icons/ArrowBack'
import TextMarkdown from '../../../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  title: {
    display: 'flex',
    padding: theme.spacing(1),
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  titleSelected: {
    display: 'flex',
    border: 'solid #E3E5E5 1px',
    backgroundColor: '#F7F9FA',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    textAlign: 'center',
  },
  heading: {
    labelAlign: 'center',
  },
  note: {
    textAlign: 'justify',
    color: theme.palette.secondary.dark,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& svg': {
      color: 'white',
    },
  },
}))

interface NomacStepLineProps {
  code: string
  label: string
  note: string
  isSelected: boolean
  handleSelect(): void
  handleReturn(): void
}

const NomacStepLine: FC<NomacStepLineProps> = props => {
  const { code, note, label, isSelected, handleSelect, handleReturn } = props
  const classes = useStyles()

  const handleClickReturn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    handleReturn()
  }

  return (
    <Box className={classes.container}>
      <Box
        className={isSelected ? classes.titleSelected : classes.title}
        onClick={handleSelect}
      >
        <Box marginRight={2}>
          <Typography>{code}</Typography>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography className={classes.heading}>{label}</Typography>
        </Box>
        {isSelected ? (
          <Box>
            <IconButton
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleClickReturn}
            >
              <ArrowIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {isSelected ? (
        <Box marginY={3} className={classes.note}>
          <Typography variant="body2">
            <TextMarkdown text={note} allowDangerousHtml={true} />
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}

export default NomacStepLine
