import { Box, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: `1px solid #3E41F7`,
  },
  innerCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#DADBFF',
  },
}))

interface NomacCircleProps {
  label?: string
}

const NomacCircle: FC<NomacCircleProps> = props => {
  const label = props.label || ''
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.index}>
        <Box className={classes.innerCircle}>
          {/* <Typography variant="caption"> {label} </Typography> */}
        </Box>
      </Box>
    </Box>
  )
}

export default NomacCircle
