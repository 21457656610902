import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { connect, useSelector } from 'react-redux'
import { ActivityCategories } from '../../../../../../../hooks/nomac/useActivityCategories'
import { NomacSelection } from '../../../../../../../interfaces/nomac'
import {
  UpdateSelectedNomac,
  updateSelectedNomac,
} from '../../../../../../../redux/modules/nomac'
import { RootState } from '../../../../../../../redux/store'
import { getInternationalizedValue } from '../../../../../../../utils/common'
import { getActivityCode } from '../../../../../../../utils/nomac'
import NomacCircle from '../../nomacCircle'
import NomacStepLine from '../../nomacStepLine'

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  divider: {
    marginLeft: theme.spacing(1.75),
    width: 4,
    backgroundColor: '#E5E5E5',
    borderRadius: 4,
    minHeight: theme.spacing(2),
  },
}))

interface CategoriesProps {
  selected: NomacSelection
  categories: ActivityCategories[]
  updateSelectedNomac: UpdateSelectedNomac
}

const Categories: FC<CategoriesProps> = props => {
  const { selected, categories, updateSelectedNomac } = props
  const classes = useStyles()
  const { language } = useSelector((state: RootState) => state.app)

  const classeCode = getActivityCode(selected.classeId)

  const currentCategories = selected.classeId
    ? categories.filter(c => c.codeClasse === classeCode)
    : []

  const handleSelectCategories = (categoryId: string) => () => {
    updateSelectedNomac({ ...selected, categoryId })
  }

  const handleResetClasses = () => {
    // Reset group and step bellow it
    updateSelectedNomac({
      ...selected,
      categoryId: '',
    })
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <NomacCircle />
        <Box>
          <Typography variant="h6"> Catégories </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box width="100%" paddingY={1} paddingX={3}>
          {currentCategories.map(c => {
            const code = getActivityCode(c.id)
            const label = getInternationalizedValue(
              c,
              'descriptivesCategorie',
              language,
            )
            const note = getInternationalizedValue(c, 'note', language)
            return (
              <NomacStepLine
                key={`divisions-${c.id}`}
                code={code}
                label={label}
                note={note}
                isSelected={selected.categoryId === c.id}
                handleSelect={handleSelectCategories(c.id)}
                handleReturn={handleResetClasses}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: RootState) => ({
  selected: state.nomac.selection,
  categories: state.nomac.data.categories,
})

const mapDispatchToProps = {
  updateSelectedNomac,
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
