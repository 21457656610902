import { graphql, useStaticQuery } from 'gatsby'

export interface ActivityCategories {
  id: string
  note_fr: string | null
  descriptivesCategorie_fr: string | null
  codeClasse: string
  codeGroupe: string
  codeDivision: string
  codeSection: string
}

export default (): ActivityCategories[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiActivityCategories {
        nodes {
          id
          note_fr
          descriptivesCategorie_fr
          codeClasse
          codeGroupe
          codeDivision
          codeSection
        }
      }
    }
  `)

  return data.allStrapiActivityCategories.nodes
}
