import { graphql, useStaticQuery } from 'gatsby'

export interface ActivityDivisions {
  id: string
  codeSectionActivite: string
  note_fr: string | null
  libelleDivisionActivite_fr: string | null
}

export default (): ActivityDivisions[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiActivityDivisions {
        nodes {
          id
          codeSectionActivite
          note_fr
          libelleDivisionActivite_fr
        }
      }
    }
  `)

  return data.allStrapiActivityDivisions.nodes
}
