import { graphql, useStaticQuery } from 'gatsby'

export interface NomacIntroData {
  id: string
  introduction_fr: string
}

export default (): NomacIntroData => {
  const data = useStaticQuery(graphql`
    {
      strapiIndroductionNomac {
        id
        introduction_fr
      }
    }
  `)

  return data.strapiIndroductionNomac
}
