import { graphql, useStaticQuery } from 'gatsby'

export interface ActivitySections {
  id: string
  note_fr: string | null
  libelleSectionActivite_fr: string | null
}

export default (): ActivitySections[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiActivitySections {
        nodes {
          id
          note_fr
          libelleSectionActivite_fr
        }
      }
    }
  `)

  return data.allStrapiActivitySections.nodes
}
